/* General Styles */
.privacy-page {
    color: whitesmoke;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh; /* Covers full viewport height */
    width: 100vw; /* Spans full viewport width */
    text-align: center;
    background: linear-gradient(135deg, 
        #1d003a 0%,    /* very dark purple at top-left */
        #2e003e 20%,   /* dark purple near top */
        #3e0067 40%,   /* dark purple with a hint of brightness */
        #7a2d8c 60%,   /* bright purple smidge in the center */
        #001f59 80%,   /* deep blue near bottom */
        #1d003a 100%   /* very dark purple again at bottom-right */
    );
    background-size: cover;
    background-position: center;
    overflow: auto;
    scrollbar-width: none; /* Hides scrollbar in Firefox */
}

/* Header */
.privacy-header {
    margin-top: 3%;
    padding: 20px 15px;
    text-align: center;
}

.privacy-header h1 {
    font-family: 'Bebas Neue', sans-serif;
    margin: 0;
    font-size: 2.5rem;
    color: pink;
}

.privacy-header p {
    margin: 10px 0 0;
    font-size: 1.2rem;
    color: whitesmoke;
}

/* Content */
.privacy-content {
    color: whitesmoke;
    padding: 20px;
    box-shadow: inset 3px 4px 15px rgba(0, 0, 0, 0.5);
    margin-bottom: 3%;
    width: 90%; /* Adjusts width for smaller screens */
    max-width: 800px; /* Prevents it from being too wide on large screens */
}

.privacy-section {
    margin-bottom: 20px;
}

.privacy-section h2 {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.8rem;
    color: pink;
    margin-bottom: 10px;
}

.privacy-section p {
    font-size: 1rem;
    margin: 0;
}

.privacy-section a {
    color: pink;
    text-decoration: none;
}

.privacy-section a:hover {
    text-decoration: underline;
    color: black;
}

/* Footer */
.footer-privacy {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    padding: 10px 0;
}

.footer-privacy p {
    font-size: 0.8rem;
    color: whitesmoke;
    text-align: center;
    margin: 5px 0;
    width: 200%;

}

/* Responsive Design */
@media (max-width: 768px) {
    .privacy-header h1 {
        font-size: 2rem;
    }

    .privacy-header p {
        font-size: 1rem;
    }

    .privacy-content {
        padding: 10px;
    }

    .privacy-section h2 {
        font-size: 1.5rem;
    }

    .privacy-section p {
        font-size: 0.9rem;
    }



    .footer-privacy p {
        font-size: 0.7rem;
    }
}

@media (max-width: 480px) {
    .privacy-header h1 {
        font-size: 1.8rem;
    }

    .privacy-header p {
        font-size: 0.9rem;
    }

    .privacy-section h2 {
        font-size: 1.3rem;
    }

    .privacy-section p {
        font-size: 0.8rem;
    }
}

@media (min-width: 1800px) and (min-height: 1600px) {
    .privacy-header h1 {
        font-size: 6rem; /* Extra-large header text for maximum impact */
        margin-bottom: 30px;
    }

    .privacy-header p {
        font-size: 3rem; /* Enlarged subtitle text */
        margin-top: 15px;
    }

    .privacy-content {
        max-width: 55%; /* Wider container for content */
        padding: 40px; /* Extra padding for spacious feel */
        font-size: 1.5rem; /* Bigger text for readability */
        margin: 30px auto; /* Center with more spacing */
        line-height: 2; /* Increased line height */
    }

    .privacy-section h2 {
        font-size: 4rem; /* Significantly larger section headers */
        margin-bottom: 20px; /* Adjust spacing for balance */
    }

    .privacy-section p {
        font-size: 2.5rem; /* Larger text size for content paragraphs */
        line-height: 2; /* Improved readability */
        margin-bottom: 20px;
    }

    .footer-privacy p {
        font-size: 1.2rem; /* Enlarged footer text */
        margin: 20px 0;
    }

    .privacy-page {
        padding: 6%; /* Additional padding for container */
    }

    /* Adjusting the main page for better readability */
    .privacy-page {
        font-size: 1.5rem;
    }
}


@media (min-width: 5000px) and (min-height: 2600px) {
    .privacy-page { 
        top:0%;
    }
    .privacy-header h1 {
        font-size: 9rem; /* Extra-large header text for maximum impact */
        margin-bottom: 30px;
    }

    .privacy-header p {
        font-size: 5rem; /* Enlarged subtitle text */
        margin-top: 15px;
    }

    .privacy-content {
        max-width: 55%; /* Wider container for content */
        padding: 40px; /* Extra padding for spacious feel */
        font-size: 1.5rem; /* Bigger text for readability */
        margin: 30px auto; /* Center with more spacing */
        line-height: 2; /* Increased line height */
    }

    .privacy-section h2 {
        font-size: 6rem; /* Significantly larger section headers */
        margin-bottom: 20px; /* Adjust spacing for balance */
    }

    .privacy-section p {
        font-size: 3.5rem; /* Larger text size for content paragraphs */
        line-height: 2; /* Improved readability */
        margin-bottom: 20px;
        color: whitesmoke;
    }

    .footer-privacy p {
        font-size: 1.2rem; /* Enlarged footer text */
        margin: 20px 0;
    }

    .footer-privacy { 
        margin-top: 2%;
    }
    .privacy-page {
        padding: 6%; /* Additional padding for container */
    }

    /* Adjusting the main page for better readability */
    .privacy-page {
        font-size: 1.5rem;
    }
}
