
.full-page {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    scrollbar-width: none;
    z-index: 1;
    height: 100vh;
  background: linear-gradient(135deg, 
    #1d003a 0%,    /* very dark purple at top-left */
    #2e003e 20%,   /* dark purple near top */
    #3e0067 40%,   /* dark purple with a hint of brightness */
    #7a2d8c 60%,   /* bright purple smidge in the center */
    #001f59 80%,   /* deep blue near bottom */
    #1d003a 100%   /* very dark purple again at bottom-right */
  );
  background-size: cover;
  background-position: center;
  color: aliceblue;

}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.6%;
    size: 20px;
    font-size: 10px;
    
}

.header button {
    padding: 15px 20px;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    border-radius: 15px;
    border: 2px solid purple;
    background: linear-gradient(90deg, rgb(49, 5, 103), rgb(64, 10, 48)); /* Cool gradient for button */
    color: white;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    font-family: 'Montserrat', sans-serif;
}
.header button:hover {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
    

}

.main {
    margin-top: -1.25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.main h1 {
    font-size: 120px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-family: 'Bebas Neue', sans-serif;
    color: #d9c5d3;
    text-shadow: 
    1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
  position: relative;
  
}



.build { 
    display: block;
  text-align: center;
  width: 100%;
}



.body {
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center
    

}
.contents-body {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.body h2 {
    font-size: 73px;
    font-family: 'Bebas Neue', sans-serif;
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.6);
    margin-bottom: 2%;
}

.body p {
    margin-bottom: -11%;
    align-items: center;
    justify-content: center;
    margin-left: 0.5%;
}

.services {
    display: flex;
    padding: 30px;
    box-shadow: inset 4px 4px 15px rgba(0, 0, 0, 0.5);

}

.content-services section {
    display: flex;
    gap: 20px; /* Adjust gap as needed */
    flex-wrap: wrap; /* Ensures blocks wrap onto the next line if there's not enough space */
  }
  
  .service-block {
    background: linear-gradient(to bottom, #000000, #2e003e);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Example shadow */
    width: calc(33% - 20px); /* Adjust based on desired width */
    min-width: 250px; /* Ensures a minimum width for smaller screens */
    padding: 48px;
    margin: 4px;
    box-sizing: border-box;
  }


  .cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    
  }

.cta p { 
    margin-top: 7%;
    margin-bottom: -5%;
}

.cta h2 {
    font-size: 90px;
    font-family: 'Bebas Neue', sans-serif;
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.6);
}

.privacy { 
  display: flex;
  justify-content: center;
  margin-top: -4%;
  font-family: 'Montserrat', sans-serif;
  font-size: 70%;
  text-shadow: black 1px solid;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Space between input and button */
    padding: 5px;
    border-color: white; /* Thickness of the outline */
    background: linear-gradient(90deg, #82227d, purple, blue);
    border-image-source: linear-gradient(90deg, darkblue, purple); /* Gradient for the border */
    border-image-slice: 1;
    border-radius: 25px; /* Rounded shape */
    /* background: #1a1a1a; Dark background inside the form */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); /* Soft shadow for form */
    margin-bottom: 5%;
    margin-top: -4%;
  }

  a { 
    color: pink;
  }
  
  a:hover { 
    color: black;
    
  }
  .form input {
    padding: 25px 45px;
    font-size: 22px;
    border: none;
    outline: none;
    border-radius: 15px;
    width: 300px;
    background: #2a2a2a; /* Dark background for input */
    color: white;
    box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.5); /* Inner shadow for depth */
    font-family: 'Montserrat', sans-serif;
  }
  
  .form input::placeholder {
    color: #999; /* Placeholder text color */
  }
  
  .form button {
    padding: 15px 20px;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    background: linear-gradient(90deg, purple, darkblue); /* Cool gradient for button */
    color: white;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    font-family: 'Montserrat', sans-serif;
  }
  
  .form button:hover {
    background: linear-gradient(90deg, darkblue, purple); /* Reverse gradient on hover */
    transform: scale(1.05); /* Slightly enlarges the button */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.6); /* Stronger shadow */
  }
  
.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5%;
    font-size: 10px;

}


/* allcoremessages */

.main p {
    margin-top: -5%;
    margin-bottom: 6%;
    font-size: 30px;
}
.coremsg1 {
    display: flex;
    justify-content: center;
    
}
p {
    font-size: 20px;
    color: 
    #bac4e3
    ;
    font-family: 'Montserrat', sans-serif;
    text-shadow: 
    1px 1px 2px rgba(53, 3, 123, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(113, 14, 144, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(18, 35, 161, 0.0001); /* More depth for the inner shadow */
  position: relative;
};
    
/* visuals */
/* Basic SVG and circle styles */
/* Basic SVG and circle styles */
.circles-svg {
    display: block;
    margin: 0 auto;
    
    
  }
  
  .circle1, .circle2 {
    transition: cx 4s ease-in-out; /* Smooth movement */
  }
  
  /* Animation when circles are moving */
  .circle1.move {
    cx: 360; /* Move circle1 closer */
  }
  
  .circle2.move {
    cx: 700; /* Move circle2 closer */
  }
  
  /* Shake animation when circles touch */

  
  /* No reset of cx after movement */
  .circle1.move, .circle2.move {
    transition: none; /* No further transition after move */
  }
  

  .circle1.shake, .circle2.shake {
    animation: shake 2s ease-in-out; /* Shake animation lasts for 3 seconds */
    animation-iteration-count: infinite; /* Make sure it happens only once */
  }
  
  /* Keyframes for shake effect */
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
  }
  
  /* Text appearance when the circles finish shaking */
 

  /* ignore */
  .visuals {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 500px;
    
    
  }
  
  /* .set-visual {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.5); /* Soft shadow for form */
    /* width: 90%;
    z-index: 10; */
  /* } */

  .set-visual {
  position: relative; /* Keeps particles inside this container */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%; /* Or a specific width you need */
  height: 500px; /* Set a fixed height or any custom height */
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5); /* Box shadow to create depth */
  overflow: hidden; /* Prevent particles from overflowing */
  z-index: 1; /* Ensures that your content stays above the particles */
}

  /* text container */
  .text1, .text2 {
    position: absolute;
    font-size: 24px;
    opacity: 0;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fadeIn {
    opacity: 1;
    transform: translateY(0);
}

.fadeOut {
    opacity: 0;
    transform: translateY(-20px);
}

.text1 {
    left: 35.5%;
    top: 38%;
    width: 30px;
    text-shadow: none;
    color: black;
    font-weight: bold;
    font-size: 23px;
    
}

.text2 {
  width: 30px;
    left: 60%;
    top: 38%;
    font-size: 23px;
    color: aliceblue;
    text-shadow: none;
    font-weight: bold;
}


.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire screen */
  z-index: -1; /* Makes sure the video is behind other content */
  opacity: 8.5%;
  pointer-events: none;
}

.logo {
margin-left: 0.5%;
 width: 120px;
 height: 60px;
}



.waitlist { 
  margin-right: 0.5%;
}
/* particles */
/* #particles { */
  /* position: absolute; Position it inside the set-visual container */
  /* top: 0; */
  /* left: 0; */
  /* width: 100%; Matches the width of .set-visual */
  /* height: 100%; Matches the height of .set-visual */
  /* z-index: -1; Ensures particles stay behind content */
/* } */


/* For tablets */



/* For phones */
@media (max-width: 480px) {

  .circle1 {
    cx: 32%;
}

.circle2 {
    cx: 66.5%;
}

  


  .text1 {
    left: 28.5%;
    top: 70px;
    color: black;
    width: 28px;
    text-shadow: none;
    font-weight: bold;
    font-size: 9.5px;
    
}


.text2 {
    left: 60%;
    top: 70px;
    font-size: 9.5px;
    width: 28px;
    color: aliceblue;
    text-shadow: none;
    font-weight: bold;
}

.set-visual {
  position: relative; /* Keeps particles inside this container */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Or a specific width you need */
  height: 200px; /* Set a fixed height or any custom height */
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5); /* Box shadow to create depth */
  overflow: hidden; /* Prevent particles from overflowing */
  z-index: 1; /* Ensures that your content stays above the particles */
}
  

.header button { 
  padding: -40px;
  font-size: 10px;
  margin: 10px;
  padding-top: 1%;
  
}

.header { 
  margin-top: 5px;
  height: 30px
}

.main h1 {
  color: #f7eded;
  font-size: 47.5px;
  margin-top: 70px;
  margin-bottom: 8%;
}


.waitlist {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px; /* Adjust spacing */
  margin: 0; /* Remove extra space */
  line-height: 1.2; /* Match text height */
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.main p {
  font-size: 15px;
  margin-top: -25px;
  margin-bottom: 60px;
  
}

.background-video 
{
  pointer-events: none;
}

.body h2 {
  font-size: 45px;
}

.body p { 
  margin-left: 10%;
  font-size: 12px;
}

.service-block { 
  margin-left: 15%;
}

.service-block p { 
  font-size: 14px;
}

.cta h2{ 
  font-size: 55px;
}

.cta p { 
  margin-bottom: -12%;
  font-size: 12px;
}


.form input{ 
  width: 50%;
  height: 16px;
  font-size: 16px;
}

.form button { 
  font-size: 15px;
  padding: 2%;

}

.footer { 
  margin-top: 9%;
}

.logo { 
  height: 30px;
  width: 60px;
}

p { 
  font-size: 10px;
}

}


/* For tablets/iPads */
@media (min-width: 601px) and (max-width: 1026px) {
  .circle1 {
    cx: 32%;
}

.circle2 {
    cx: 67%;
}
.circle2.move {
  cx: 63%; /* Move circle2 closer */
}
  
  .text1 {
    left: 28%;
    top: 35%;
    color: black;
    width: 35px;
    font-weight: bold;
    font-size: 130%;
  }

  .text2 {
    left: 59.5%;
    top: 35%;
    font-size: 130%;
    color: white;
    width: 35px;
    font-weight: bold;
  }

  .set-visual {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%; /* Adjusted for better fit */
    height: 400px; /* Slightly larger for tablets */
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5); /* Box shadow remains */
    overflow: hidden;
    z-index: 1;
  }

  .header button {
    /* padding: 20px 25px; */
    padding: 25px 50px;
    padding-top: 5px;
    font-size: 15px;
    margin: 15px;
  }

  .header {
    margin-top: 10px;
    height: 40px;
  }

  .main h1 {
    font-size: 100px;
    margin-top: 80px;
  }

  .main p {
    font-size: 25px;
    margin-top: -70px;
    margin-bottom: 100px;
  }

  .body h2 {
    font-size: 80px;
  }

  .body p {
    margin-left: 12%;
    font-size: 18px;
  }

  .service-block {
    margin-left: 15%;
    width: 70%;
  }
.services { 
  margin-bottom: 3%;
}

  .cta h2 {
    font-size: 100px;
    margin-bottom: 5%;
  }

  .cta p {
    margin-bottom: -10%;
  }

  .form input {
    width: 300px;
    height: 30px;
    font-size: 20px;
  }

  .form button {
    font-size: 16px;
    padding: 3%;
  }

  .footer {
    margin-top: 5%;
  }

  .logo {
    height: 60px;
    width: 110px;
  }

  p {
    font-size: 18px;
  }
}


@media (min-width: 480px) and (max-width: 540px) and (min-height: 700px) and (max-height: 720px) {

  .text1 {
    left: 30%;
    top: 80px;
    width: 30px;
    color: black;
    text-shadow: none;
    font-weight: bold;
    font-size: 12px;
    
}


.text2 {
    left: 62%;
    top: 80px;
    width: 30px;
    font-size: 12px;
    color: aliceblue;
    text-shadow: none;
    font-weight: bold;
}

  .set-visual {
    height: 180px;
  }

  .main h1 {
    font-size: 70px;
    margin-top: 60px;
  }

  .main p {
    font-size: 16px;
    margin-top: -40px;
    margin-bottom: 70px;
  }

  .body h2 { 
    font-size: 70px;
  }

  .body p { 
    font-size: 15px;
  }

  .cta h2 { 
    font-size: 70px;
  }

  .cta p { 
    font-size: 15px;
  }

  .footer { 
    margin-top: 40px;
    margin-bottom: -20px;
  }

  .form input {
    width: 80%;
    height: 15px;
    font-size: 14px;
  }

  .form button {
    font-size: 14px;
    padding: 3%;

  }

  .logo {
    margin-top: 10px;
    height: 35px;
    width: 70px;
  }
}


@media (min-width: 320px) and (max-width: 344px) and (min-height: 870px) and (max-height: 890px) {

  .text1 {
    left: 31%;
    width: 20px;
    top: 80px;
    color: black;
    font-size: 10px;
    text-shadow: none;
  }

  .text2 {
    left: 60%;
    width: 20px;
    top: 80px;
    font-size: 10px;
    color: aliceblue;
    
    
  }

  .set-visual {
    height: 180px;
  }

  .main h1 {
    font-size: 45px;
    margin-top: 70px;
  }

  .main p {
    font-size: 14px;
    margin-top: -30px;
    margin-bottom: 70px;
  }

  .body h2 { 
    font-size: 45px;
    margin-left: 5px
  }


  .services { 
    margin-bottom: 20px;
  }
  .body p { 

    font-size: 11px;
  }

  .cta h2 { 
    font-size: 50px;
  }

  .cta p { 
    font-size: 11px;
  }

  .footer { 
    margin-top: 40px;
    margin-bottom: -20px;
  }

  .footer p { 
    margin-top: 25px;
  }

  .form input {
    width: 80%;
    height: 15px;
    font-size: 14px;
  }

  .form button {
    font-size: 14px;
    padding: 3%;
  }

  .logo {
    margin-top: 10px;
    height: 30px;
    width: 60px;
  }
}





@media(min-width: 1270px) and (max-width: 1290px) and (min-height: 1020px) and (max-height: 1040px) 
{


  .circle2.move {
    cx: 50%; /* Move circle2 closer */
  }

  p {
    font-size: medium;
  }

  h1 { 
    font-size: large;
  }
  .circle1 {
    cx: 32%;
}

.circle2 {
    cx: 40%;
}

} 
@media(min-width: 1270px) and (max-width: 1290px) and (min-height: 950px) and (max-height: 983px) 
{
  .circle2.move {
    cx: 30%; /* Move circle2 closer */
  }
  p {
    font-size: 25px;
  }

  h1 { 
    font-size: 40px;
  }
  .circle1 {
    cx: 32%;
}

.circle2 {
    cx: 30%;
}

} 

@media(min-width: 1270px) and (max-width: 1290px) and (min-height: 710px) and (max-height: 723px) 
{
  .circle2.move {
    cx: 30%; /* Move circle2 closer */
  }
  p {
    font-size: 25px;
  }

  h1 { 
    font-size: 40px;
  }
  .circle1 {
    cx: 35%;
}

.circle2 {
    left: 30%;
}

.text1 { 
  left: 32%;
}

} 





@media(min-width: 2040px) and (max-width: 2580px) and (min-height: 1435px) and (max-height: 1605px) 
{
.text1 {
  left: 37%;
}

.text2 { 
  left: 52%;
}

}


/*  MACS */

@media(min-width: 1300px) and (min-height: 800px) {
  .circle1 {
    cx: 32%;
}

.circle2 {
    cx: 60%;
}

  
}

/* macbook air 13 inch  */
@media (min-width: 1380px) and (max-width: 1500px) and (min-height: 800px) and (max-height: 1000px) {


  
  .main h1 { 
    font-size: 130px;
  }




/* allcoremessages */
.circle1 {
  cx: 32%;
}

.circle2 {
  cx: 60%;
}


.main p {
    margin-top: -5%;
    margin-bottom: 6%;
    font-size: 30px;
}
.coremsg1 {
    display: flex;
    justify-content: center;
    
}
p {
}
    

  .set-visual {
  position: relative; /* Keeps particles inside this container */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%; /* Or a specific width you need */
  height: 500px; /* Set a fixed height or any custom height */
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5); /* Box shadow to create depth */
  overflow: hidden; /* Prevent particles from overflowing */
  z-index: 1; /* Ensures that your content stays above the particles */
}



.text1 {
  width: 30px;
    left: 35%;
    top: 205px;
    text-shadow: none;
    color: black;
    font-weight: bold;
    font-size: 24px;
    
}

.text2 {
    left: 60%;
    top: 205px;
    font-size: 25px;
    width: 30px;
    color: aliceblue;
    text-shadow: none;
    font-weight: bold;
}


.logo {
margin-left: 0.5%;
 width: 130px;
 height: 70px;
}



.waitlist { 
  margin-right: 0.5%;
}

};




@media (min-width: 2000px) and (max-width: 2565px) and (min-height: 1000px) and (max-height: 1605px) {
  .circle1 {
    cx: 40%;  
}

.circle2 {
    cx: 65%;
}

  
  .text1 {
    width: 30px;
      left: 40%;
      top: 205px;
      text-shadow: none;
      color: black;
      font-weight: bold;
      font-size: 24px;
      
  }
  
  .text2 {
      left: 50%;
      top: 205px;
      font-size: 25px;
      width: 30px;
      color: aliceblue;
      text-shadow: none;
      font-weight: bold;
  }
  

}


/* macbooks */


/* MacBook Pro 13-inch (2016–2020) */
@media (min-width: 2550px) and (max-width: 2570px) and (min-height: 1590px) and (max-height: 1605px) {
  /* Styles specific to MacBook Pro 13-inch */
  .circle1 {
    cx: 42%;
}

.circle2 {
    cx: 60%;
}
.circle2.move {
  cx: 65%; /* Move circle2 closer */
}
  


.set-visual {
  height: 700px;
  margin-top: 10%;

}


.main h1 { 
  font-size: 200px;
}
.main p { 
  margin-top: -120px;
  width: max-content;
  font-size: 40px;
}

  .text1 {
    width: 30px;
      left: 44%;
      top: 37%;
      text-shadow: none;
      color: black;
      font-weight: bold;
      font-size: 32px;
      
  }
  
  .text2 {
      left: 48%;
      top: 37%;
      font-size: 32px;
      width: 30px;
      color: aliceblue;
      text-shadow: none;
      font-weight: bold;
  }

.body h2 { 
  font-size: 150px;
}

  .body p { 
    margin-top: 150px;
    margin-left: 3%;
    font-size: 40px;
  }

  .service-block h3{ 
    font-size: 35px ;

  }

  .cta p { 
    margin-bottom: -5%;
  }

  .cta h2 { 
    font-size: 150px;
  }

  .form input { 
    width: 400px;
    height: 50px;
    font-size: 30px;
  }

  .form button { 
    font-size: 25px;
    padding: 20px;
  }

  p { 
    font-size: 35px;
  }
  
  .logo { 
    height: 100px;
    width: 200px;
  }
  
  .header .waitlist { 
    width: 150px;
    height: 70px;
    font-size: 22px;
    
  }
}

/* MacBook Pro 15-inch (2016–2019) */
@media (min-width: 2700px) and (max-width: 2880px) and (min-height: 1700px) and (max-height: 1800px) {
  .circle1 {
    cx: 32%;
}

.circle2 {
    cx: 60%;
}

.circle2.move {
  cx: 65%; /* Move circle2 closer */
}
 
.set-visual {
  height: 700px;
  margin-top: 10%;

}



.main h1 { 
  font-size: 220px;
}
.main p { 
  margin-top: -120px;
  width: max-content;
  font-size: 50px;
}

  .text1 {
    width: 30px;
      left: 40%;
      top: 37%;
      text-shadow: none;
      color: black;
      font-weight: bold;
      font-size: 32px;
      
  }
  
  .text2 {
      left: 55%;
      top: 37%;
      font-size: 32px;
      width: 30px;
      color: aliceblue;
      text-shadow: none;
      font-weight: bold;
  }

.body h2 { 
  font-size: 150px;
}

  .body p { 
    margin-top: 150px;
    margin-left: 3%;
    font-size: 40px;
  }

  .service-block h3{ 
    font-size: 35px ;

  }

  .cta p { 
    margin-bottom: -4.5%;
  }

  .cta h2 { 
    font-size: 150px;
  }

  .form input { 
    width: 470px;
    height: 53px;
    font-size: 33px;
    padding: 40px;
    margin: 4px;
  }

  .form button { 
    font-size: 26px;
    padding: 25px;
  }

  p { 
    font-size: 35px;
  }
  
  .logo { 
    height: 100px;
    width: 200px;
  }
  
  .header .waitlist { 
    width: 200px;
    height: 90px;
    font-size: 27px;
    
  }
}

/* MacBook Pro 16-inch (2019+) */
@media (min-width: 1670px) and (max-width: 1690px) and (min-height: 1040px) and (max-height: 1060px) {
  .text1 { 
    left: 40%;
  }
}

/* MacBook Pro 16-inch (2019+) */
@media (min-width: 2996px) and (max-width: 3072px) and (min-height: 960px) and (max-height: 1920px) {
  .circle1 {
    cx: 32%;
}

.circle2 {
    cx: 60%;
}

.circle2.move {
  cx: 65%; /* Move circle2 closer */
}
  .set-visual {
    height: 700px;
    margin-top: 10%;
  
  }
  
  
  
  .main h1 { 
    font-size: 220px;
  }
  .main p { 
    margin-top: -120px;
    width: max-content;
    font-size: 50px;
  }
  
    .text1 {
      width: 30px;
        left: 43%;
        top: 37%;
        text-shadow: none;
        color: black;
        font-weight: bold;
        font-size: 28px;
        
    }
    
    .text2 {
        left: 55%;
        top: 37%;
        font-size: 28px;
        width: 30px;
        color: aliceblue;
        text-shadow: none;
        font-weight: bold;
    }
  
  .body h2 { 
    font-size: 150px;
  }
  
    .body p { 
      margin-top: 150px;
      margin-left: 3%;
      font-size: 40px;
    }
  
    .services {
      padding: 4%;
    }

    .service-block h3{ 
      font-size: 40px ;
  
    }
  
    .service-block p { 
      font-size: 40px;
    }
    .cta p { 
      margin-bottom: -4.5%;
    }
  
    .cta h2 { 
      font-size: 150px;
    }
  
    .form input { 
      width: 470px;
      height: 53px;
      font-size: 33px;
      padding: 40px;
      margin: 4px;
    }
  
    .form button { 
      font-size: 26px;
      padding: 25px;
    }
  
    p { 
      font-size: 35px;
    }
    
    .logo { 
      height: 100px;
      width: 200px;
    }
    
    .header .waitlist { 
      width: 200px;
      height: 90px;
      font-size: 27px;
      
    }
}

/* iMac 21.5-inch (2019+) */
@media (min-width: 1890px) and (max-width: 1920px) and (min-height: 1000px) and (max-height: 1080px) {
  .circle1 {
    cx: 32%;
}

.circle2 {
    cx: 60%;
}

  
  /* Styles specific to iMac 21.5-inch */
    .set-visual {
      height: 700px;
      margin-top: 10%;
    
    }
    
    
    
    .main h1 { 
      font-size: 150px;
    }
    .main p { 
      margin-top: -120px;
      width: max-content;
      font-size: 40px;
    }
    
      .text1 {
        width: 30px;
          left: 38%;
          top: 39%;
          text-shadow: none;
          color: black;
          font-weight: bold;
          font-size: 25px;
          
      }
      
      .text2 {
          left: 57.5%;
          top: 39%;
          font-size: 25px;
          width: 30px;
          color: aliceblue;
          text-shadow: none;
          font-weight: bold;
      }
    
    .body h2 { 
      font-size: 130px;
    }
    
      .body p { 
        margin-top: 150px;
        margin-left: 9%;
        font-size: 30px;
      }
    
      .services {
        padding: 4%;
      }
  
      .service-block h3{ 
        font-size: 30px ;
    
      }
    
      .service-block p { 
        font-size: 25px;
      }
      .cta p { 
        margin-bottom: -7%;
      }
    
      .cta h2 { 
        font-size: 150px;
      }
    
      .form input { 
        width: 450px;
        height: 23px;
        font-size: 33px;
        padding: 40px;
        margin: 4px;
      }
    
      .form button { 
        font-size: 23px;
        padding: 23px;
      }
    
      p { 
        font-size: 30px;
      }
      
      .logo { 
        height: 100px;
        width: 200px;
      }
      
      .header .waitlist { 
        width: 200px;
        height: 90px;
        font-size: 27px;
        
      }
    }


/* iMac 27-inch (2019+) */
@media (min-width: 4000px) and (max-width: 5200px) and (min-height: 2000px) and (max-height: 2900px) {
  .circle1 {
    cx: 32%;
}

.circle2 {
    cx: 60%;
}
.circle2.move {
  cx: 65%; /* Move circle2 closer */
}
  
  /* Visual section adjustments */
  .set-visual {
    height: 1000px;
    margin-top: 5%; /* Reduced margin-top for better centering */
    padding: 20px; /* Added padding for better spacing */
  }

  
  /* Main heading styles */
  .main h1 {
    font-size: 300px; /* Adjusted font-size to avoid overflow */
    text-align: center; /* Center the main heading */
  }

  /* Main paragraph styles */
  .main p {
    margin-top: -1%; /* Adjusted margin-top for better alignment */
    margin-bottom: 6%;
    font-size: 80px; /* Reduced font-size for better readability */
    text-align: center; /* Centered text */
  }

  /* Text adjustments */
  .text1 {
    left: 65%; /* Slightly adjusted left position */
    top: 42.5%; /* Adjusted top for vertical centering */
    font-size: 27px; /* Reduced font-size for better spacing */
    color: black;
    font-weight: bold;
  }

  .text2 {
    left: 75%; /* Adjusted left position */
    top: 42.5%;
    font-size: 27px; /* Adjusted font-size */
    color: aliceblue;
    font-weight: bold;
  }

  /* Body heading adjustments */
  .body h2 {
    font-size: 200px; /* Adjusted font-size for body headings */
    text-align: center;
  }

  /* Body paragraph adjustments */
  .body p {
    margin-top: 100px; /* Adjusted margin-top */
    margin-left: 20%;
    width: 1000px;
    font-size: 35px; /* Slightly reduced font-size */
  }

  /* Services block styling */
  .services {
    padding: 5%;
    text-align: center; /* Centered services block */
  }

  .service-block h3 {
    font-size: 90px; /* Adjusted heading size */
    text-align: center;
  }

  .service-block p {
    font-size: 75px; /* Reduced font-size for better legibility */
    padding: 5%;
  }

  /* Call-to-action block */
  .cta p {
    margin-bottom: -1%; /* Reduced negative margin for better positioning */
    font-size: 50px;
    margin-top: 2%;
  }

  .cta h2 {
    font-size: 250px; /* Adjusted size for better spacing */
    text-align: center;
    margin-bottom: 5%;

    
  }

  /* Form input styles */
  .form input {
    width: 900px; /* Reduced width for better fitting */
    height: 130px;
    font-size: 70px; /* Slightly reduced font-size */
    padding: 40px; /* Added padding for better user experience */
    margin: 10px;
  }

  .form button {
    font-size: 50px; /* Reduced font-size for better fitting */
    padding: 30px;
  }

  /* General paragraph styling */
  p {
    font-size: 60px; /* Adjusted for overall consistency */
  }

  /* Logo adjustments */
  .logo {
    width: 420px; /* Reduced width for better proportions */
    height: 200px;
  }

  /* Header waitlist styling */
  .header .waitlist {
    width: 400px; /* Adjusted width */
    height: 180px;
    font-size: 60px; /* Reduced font-size */
  }
  
}


/* Style the modal container */
.alert-container {
  display: none;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f44336;
  color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 1000;
}

.alert-container.show {
  display: flex;
}

.alert-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}