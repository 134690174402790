/* Full-page container */
.full-page-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    min-height: 100vh; /* Ensures it covers the full viewport height */
    width: 100vw; /* Ensures it spans the full viewport width */
    text-align: center;
    background: linear-gradient(135deg, 
    #1d003a 0%,    /* very dark purple at top-left */
    #2e003e 20%,   /* dark purple near top */
    #3e0067 40%,   /* dark purple with a hint of brightness */
    #7a2d8c 60%,   /* bright purple smidge in the center */
    #001f59 80%,   /* deep blue near bottom */
    #1d003a 100%   /* very dark purple again at bottom-right */
  );
  background-size: cover;
  background-position: center;
  overflow: scroll;
  scrollbar-width: none;
}

/* Hide scrollbar while keeping scroll functionality */
.full-page-success::-webkit-scrollbar {
    display: none; /* Hides scrollbar in WebKit browsers */
}


/* Prevent horizontal scrolling */
body {
    overflow-x: hidden; /* Hides horizontal scrollbar */
}

/* For extra safety, apply to html too */
html {
    overflow-x: hidden;
}

/* Header styles */
.header-success {
    width: 100%;
    padding: 20px 0;
   

    
}

.home-success { 
    z-index: 2000;
    margin: 2%;
}

.header-success .logo-success {
    height: 50px;
}

p {
    font-family: 'Montserrat', sans-serif;
}

/* Body content */
.body-success {
    margin-top: -6%;
    flex: 1;
    flex-direction: column;
    max-width: 800px;
    padding: 20px;
}

.body_p-success {
    box-shadow: inset 3px 4px 15px rgba(0, 0, 0, 0.5);
    padding: 7%;
}

.body-success h1 {
    margin-top: 8%;
    margin-bottom: 40px;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 70px;
    color: aliceblue;
    text-shadow:
    1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
}

.box-text { 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    padding: 4%;
}
.body-success p {
    font-size: 22px;
    line-height: 1.5;
    color: aliceblue;
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
    text-shadow:
    1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
}

/* Form styles */
/* Form container */
.form-success {
    padding: 50px;
    background: linear-gradient(135deg, 
    #1d003a 0%,    /* very dark purple at bottom-right */
    #001f59 20%,   /* deep blue near bottom */
    #7a2d8c 40%,   /* bright purple smidge in the center */
    #3e0067 60%,   /* dark purple with a hint of brightness */
    #2e003e 80%,   /* dark purple near top */
    #1d003a 100%   /* very dark purple at top-left */
);
    margin: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: black 1px dotted;
    width: 90%;
    max-width: 500px;
    height: 40%;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    
}

.form-success p {
    text-shadow:
    1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
    color: aliceblue;
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.form-success .email-form-success {
    padding: 2%;
    font-size: 14px;
    margin-left: -5%;
    margin-top: 5%;
    width: 106%;
}

.form-success .query-form-success {
    padding: 10%;
    font-size: 14px;
    margin-left: -5%;
}

/* Email input box */
.email-form-success {
    width: 30%; /* Smaller width */
    padding: 2px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    display: block; /* Stack on top of the query box */
    margin-left: auto;
    margin-right: auto;
}

/* Query input box */
.query-form-success {
    align-items: center;
    width: 90%; /* Larger width */
    padding: 5px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    display: block; /* Stacked input fields */
    margin-left: auto;
    margin-right: auto;
}

/* Submit button */
.form-button-success {
    width: 100px;
    padding: 3px;
    font-size: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    margin: 5px auto; /* Center the button */
}

.form-button-success:hover {
    background-color: #0056b3;
}

/* Footer styles */
.footer-success {
    margin-top: 2%;
    width: 100%;
    color: #fff;
    padding: 10px 0;
    margin-bottom: 0%;
}

.footer-success .logo-success {
    margin-bottom: -1%;
    height: 55px;
    width: 105px;

}


.footer-success p {
    font-size: 0.8rem;
    margin-top: 1.5%;
    margin-bottom: -0.25%;
}




/* Media queries for responsiveness */
@media (max-width: 480px) {
    * {
        box-sizing: border-box;
    }
    /* Adjust font sizes for smaller screens */
    .body-success h1 {
        font-size: 40px; /* Smaller title for mobile */
    }

    .body-success p {
        font-size: 16px; /* Smaller text for readability */
    }

    .form-success {
        
        padding: 20px;
        width: 95%; /* Reduce width */
        height: auto; /* Allow height to adjust naturally */
    }

   
    .email-form-success{
        width: 100%; /* Full width for input fields */
        font-size: 20px; /* Adjust text size */
    }

    .form-button-success {
        width: auto; /* Fit content width */
        padding: 10px; /* Increase padding for touch-friendliness */
        font-size: 14px; /* Larger font for readability */
    }

    .footer-success p {
        font-size: 0.7rem; /* Adjust footer text size */
    }

    .logo-success {
        height: 40px; /* Smaller logo */
        width: auto; /* Maintain aspect ratio */
    }

    /* Query input box */
.query-form-success {
    box-sizing:content-box;
    align-items: center;
    width: 85%; /* Larger width */
    padding: 5px;
    font-size: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    display: block; /* Stacked input fields */
    margin-left: auto;
    margin-right: auto;
}
}


/* Media query for smaller devices */
@media (max-width: 600px) {
    .email-form-success, .query-form-success {
        width: 90%; /* Adjust to be nearly full-width */
        font-size: 0.8rem; /* Smaller font size */
        padding: 7px; /* Reduced padding for smaller inputs */


    }


    /* Email input box */
.email-form-success {
    width: 30%; /* Smaller width */
    padding: 2px;
    font-size: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    display: block; /* Stack on top of the query box */
    margin-left: auto;
    margin-right: auto;
}

.email-form-success input { 
    font-size: 20px;
}

.query-form-success input { 
    font-size: 20px;
}
/* Query input box */
.query-form-success {
    box-sizing:content-box;
    align-items: center;
    width: 85%; /* Larger width */
    padding: 5px;
    font-size: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    display: block; /* Stacked input fields */
    margin-left: auto;
    margin-right: auto;
}

    .form-button-success {
        width: 80%; /* Full-width button for better usability */
        padding: 8px;
        font-size: 0.9rem; /* Reduced font size */
    }
}

/* Medium devices (tablets, 600px to 1024px) */
@media (min-width: 601px) and (max-width: 1024px) {
    .body-success {
        margin-top: -3%;
       
    }

    .body-success h1 {
        font-size: 50px; /* Slightly smaller for tablets */
    }

    .form-success {
        padding: 30px;
        max-width: 500px;
    }
}

/* Large devices (desktops, > 1024px) */
/* For MacBook Air (common resolutions) */
@media screen and (min-width: 1400px) and (max-width: 1440px) and (min-height: 850px) and (max-height: 900px) {
    .full-page-success {
        overflow-y: scroll;
        scrollbar-width: none;
        background-size: cover; /* Ensures the background covers the screen */
        /* Keeps the background fixed */
        height: 100vh; /* Ensure full viewport height is covered */
    }

    /* Adjusting form input sizes for better fit */
    
    .form-success .query-form-success {
        width: 106%; /* Scale down inputs for MacBook width */
        padding: 10px; /* Adjust padding */
        font-size: 16px; /* Tweak font size for balance */
        
    }

    .form-button-success {
        width: 120px; /* Scale the button appropriately */
        padding: 8px;
    }
}

/* macbook pro 15 inch */
@media screen and (min-width: 2800px) and (max-width: 2880px) and (min-height: 1750px) and (max-height: 1800px) {
    .full-page-success {
        overflow-y: scroll;
        scrollbar-width: none;
        background-size: cover; /* Ensures the background covers the screen */
        /* Keeps the background fixed */
        height: 100vh; /* Ensure full viewport height is covered */
    }

    /* Adjusting form input sizes for better fit */
   
   
/* Header styles */
.header-success {
    width: 100%;
    padding: 20px 0;
    
}


.header-success .logo-success {
    height: 50px;
    margin-top: 0%;
    margin-bottom: 5%;
    height: 100px;
    width: 250;
}

p {
    font-family: 'Montserrat', sans-serif;
}

/* Body content */
.body-success {
    margin-top: -6%;
    flex: 1;
    flex-direction: column;
    max-width: 800px;
    padding: 20px;
}

.body_p-success {
    box-shadow: inset 3px 4px 15px rgba(0, 0, 0, 0.5);
    padding: 7%;
}

.body-success h1 {
    margin-top: 8%;
    margin-bottom: 40px;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 120px;
    color: aliceblue;
    text-shadow:
    1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
}

.box-text { 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    padding: 10%;
    width: 1000px;
    margin-left: -25%;
}
.body-success p {
    font-size: 40px;
    line-height: 1.5;
    color: aliceblue;
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
    text-shadow:
    1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
}

/* Form styles */
/* Form container */
.form-success {
    padding: 150px; /* 30% increase from 70px */
    width: 104%; /* 30% increase from 80% */
    margin: 40px auto; /* Keep centered */
    align-items: center;
}

.form-success p {
    font-size: 1.82rem; /* 30% increase from 1.4rem */
}

.form-success .email-form-success,
.form-success .query-form-success {
    width: 105%; /* 30% increase from 90% */
    font-size: 1.56rem; /* 30% increase from 1.2rem */
    padding: 19.5px; /* 30% increase from 15px */
    margin-bottom: 3%;
}

.form-button-success {
    width: 156px; /* 30% increase from 120px */
    padding: 10.4px; /* 30% increase from 8px */
    font-size: 1.3rem; /* 30% increase from 1rem */
    margin-top: 5%;
}
.form-button-success:hover {
    background-color: #0056b3;
}

/* Footer styles */
.footer-success {
    margin-top: 2%;
    width: 100%;
    color: #fff;
    padding: 10px 0;
    margin-bottom: 0%;
}

.footer-success .logo-success {
    margin-bottom: -1%;
    height: 100px;
    width: 200px;

}


.footer-success p {
    font-size: 30px;
    margin-top: 1.5%;
    margin-bottom: -0.25%;
}





}


/* macbook pro 13 inch */
@media screen and (min-width: 2500px) and (max-width: 2560px) and (min-height: 1550px) and (max-height: 1600px) {
    .full-page-success {
        overflow-y: scroll;
        scrollbar-width: none;
        background-size: cover; /* Ensures the background covers the screen */
        /* Keeps the background fixed */
        height: 100vh; /* Ensure full viewport height is covered */
    }

    /* Adjusting form input sizes for better fit */
   
   
/* Header styles */
.header-success {
    width: 100%;
    padding: 20px 0;
    
}


.header-success .logo-success {
    height: 50px;
    margin-top: 0%;
    margin-bottom: 5%;
    height: 100px;
    width: 250;
}

p {
    font-family: 'Montserrat', sans-serif;
}

/* Body content */
.body-success {
    margin-top: -6%;
    flex: 1;
    flex-direction: column;
    max-width: 800px;
    padding: 20px;
}

.body_p-success {
    box-shadow: inset 3px 4px 15px rgba(0, 0, 0, 0.5);
    padding: 7%;
}

.body-success h1 {
    margin-top: 8%;
    margin-bottom: 40px;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 120px;
    color: aliceblue;
    text-shadow:
    1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
}

.box-text { 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    padding: 10%;
    width: 1000px;
    margin-left: -25%;
}
.body-success p {
    font-size: 40px;
    line-height: 1.5;
    color: aliceblue;
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
    text-shadow:
    1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
}

/* Form styles */
/* Form container */
.form-success {
    padding: 150px; /* 30% increase from 70px */
    width: 104%; /* 30% increase from 80% */
    margin: 40px auto; /* Keep centered */
    align-items: center;
}

.form-success p {
    font-size: 1.82rem; /* 30% increase from 1.4rem */
}

.form-success .email-form-success,
.form-success .query-form-success {
    width: 105%; /* 30% increase from 90% */
    font-size: 1.56rem; /* 30% increase from 1.2rem */
    padding: 19.5px; /* 30% increase from 15px */
    margin-bottom: 3%;
}

.form-button-success {
    width: 156px; /* 30% increase from 120px */
    padding: 10.4px; /* 30% increase from 8px */
    font-size: 1.3rem; /* 30% increase from 1rem */
    margin-top: 5%;
}
.form-button-success:hover {
    background-color: #0056b3;
}

/* Footer styles */
.footer-success {
    margin-top: 2%;
    width: 100%;
    color: #fff;
    padding: 10px 0;
    margin-bottom: 0%;
}

.footer-success .logo-success {
    margin-bottom: -1%;
    height: 100px;
    width: 200px;

}


.footer-success p {
    font-size: 30px;
    margin-top: 1.5%;
    margin-bottom: -0.25%;
}





}

/* macbook pro 16 inch */
@media screen and (min-width: 3000px) and (max-width: 3080px) and (min-height: 1900px) and (max-height: 1950px) {
    .full-page-success {
        overflow-y: scroll;
        scrollbar-width: none;
        background-size: cover; /* Ensures the background covers the screen */
        /* Keeps the background fixed */
        height: 100vh; /* Ensure full viewport height is covered */
    }

    /* Adjusting form input sizes for better fit */
   
   
/* Header styles */
.header-success {
    width: 100%;
    padding: 20px 0;
    
}


.header-success .logo-success {
    height: 50px;
    margin-top: 0%;
    margin-bottom: 5%;
    height: 100px;
    width: 250;
}

p {
    font-family: 'Montserrat', sans-serif;
}

/* Body content */
.body-success {
    margin-top: -6%;
    flex: 1;
    flex-direction: column;
    max-width: 800px;
    padding: 20px;
}

.body_p-success {
    box-shadow: inset 3px 4px 15px rgba(0, 0, 0, 0.5);
    padding: 7%;
}

.body-success h1 {
    margin-top: 8%;
    margin-bottom: 40px;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 120px;
    color: aliceblue;
    text-shadow:
    1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
}

.box-text { 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    padding: 10%;
    width: 1000px;
    margin-left: -25%;
}
.body-success p {
    font-size: 40px;
    line-height: 1.5;
    color: aliceblue;
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
    text-shadow:
    1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
}

/* Form styles */
/* Form container */
.form-success {
    padding: 150px; /* 30% increase from 70px */
    width: 104%; /* 30% increase from 80% */
    margin: 40px auto; /* Keep centered */
    align-items: center;
}

.form-success p {
    font-size: 1.82rem; /* 30% increase from 1.4rem */
}

.form-success .email-form-success,
.form-success .query-form-success {
    width: 105%; /* 30% increase from 90% */
    font-size: 1.56rem; /* 30% increase from 1.2rem */
    padding: 19.5px; /* 30% increase from 15px */
    margin-bottom: 3%;
}

.form-button-success {
    width: 156px; /* 30% increase from 120px */
    padding: 10.4px; /* 30% increase from 8px */
    font-size: 1.3rem; /* 30% increase from 1rem */
    margin-top: 5%;
}
.form-button-success:hover {
    background-color: #0056b3;
}

/* Footer styles */
.footer-success {
    margin-top: 2%;
    width: 100%;
    color: #fff;
    padding: 10px 0;
    margin-bottom: 0%;
}

.footer-success .logo-success {
    margin-bottom: -1%;
    height: 100px;
    width: 200px;

}


.footer-success p {
    font-size: 30px;
    margin-top: 1.5%;
    margin-bottom: -0.25%;
}





}


/* mac 27 inch */
@media screen and (min-width: 5000px) and (max-width: 5280px) and (min-height: 2750px) and (max-height: 2900px) {
    .full-page-success {
        overflow-y: scroll;
        scrollbar-width: none;
        background-size: cover; /* Ensures the background covers the screen */
        /* Keeps the background fixed */
        height: 100vh; /* Ensure full viewport height is covered */
    }

    /* Adjusting form input sizes for better fit */
   
   
/* Header styles */
.header-success {
    width: 100%;
    padding: 20px 0;
    
}


.header-success .logo-success {
    height: 50px;
    margin-top: 0%;
    margin-bottom: 5%;
    height: 200px;
    width: 350px;
    margin-left: 5%;
}

p {
    font-family: 'Montserrat', sans-serif;
}

/* Body content */
.body-success {
    margin-top: -6%;
    flex: 1;
    flex-direction: column;
    max-width: 800px;
    padding: 20px;

}

.body_p-success {
    box-shadow: inset 3px 4px 15px rgba(0, 0, 0, 0.5);
    padding: 7%;
}

.body-success h1 {
    margin-top: 8%;
    margin-bottom: 40px;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 200px;
    width: 1500px;
    margin-left: -30%;
    color: aliceblue;
    text-shadow:
    1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
}

.box-text { 
    box-shadow: none;
    margin-left: -20%;
    
  
}
.body-success p {
    font-size: 100px;
    width: 3000px;
    margin-left: -95%;
    line-height: 1.5;
    color: aliceblue;
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
    text-shadow:
    1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
    -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
    0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
}

/* Form styles */
/* Form container */
.form-success {
    padding: 300px; /* 30% increase from 70px */
    padding-right: 30%;
    width: 900%; /* 30% increase from 80% */
    margin: 40px auto; /* Keep centered */
    align-items: center;
    margin-left: 30%;
}

.form-success p {
    font-size: 5rem; /* 30% increase from 1.4rem */
    width: 1500px;
    margin-top: -30%;
    margin-left: 20%;
    margin-bottom: 20%;
}

.form-success .email-form-success,
.form-success .query-form-success {
    width: 300%; /* 30% increase from 90% */
    font-size: 4rem; /* 30% increase from 1.2rem */
    padding: 19.5px; /* 30% increase from 15px */
    margin-left: 15%;
    margin-bottom: 4%;
}

.form-button-success {
    width: 350px; /* 30% increase from 120px */
    padding: 10.4px; /* 30% increase from 8px */
    font-size: 4rem; /* 30% increase from 1rem */
    margin-top: 15%;
    margin-left: 120%;
}
.form-button-success:hover {
    background-color: #0056b3;
}

/* Footer styles */
.footer-success {
    margin-left: 4%;
    margin-top: 2%;
    width: 300%;
    color: #fff;
    padding: 10px 0;
    margin-bottom: 0%;
}

.footer-success .logo-success {
    margin-bottom: -1%;
    height: 200px;
    width: 350px;

}


.footer-success p {
    font-size: 60px;
    margin-top: 1.5%;
    margin-bottom: -0.25%;
}





}

/* 1920 x 1080 */
/* mac 27 inch */
@media screen and (min-width: 1900px) and (max-width: 1920px) and (min-height: 1050px) and (max-height: 1080px) {
    .full-page-success {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: space-between; */
        min-height: 100vh; /* Ensures it covers the full viewport height */
        width: 100vw; /* Ensures it spans the full viewport width */
        text-align: center;
        background: linear-gradient(135deg, 
        #1d003a 0%,    /* very dark purple at top-left */
        #2e003e 20%,   /* dark purple near top */
        #3e0067 40%,   /* dark purple with a hint of brightness */
        #7a2d8c 60%,   /* bright purple smidge in the center */
        #001f59 80%,   /* deep blue near bottom */
        #1d003a 100%   /* very dark purple again at bottom-right */
      );
      background-size: cover;
      background-position: center;
      overflow-y: hidden;
      scrollbar-width: none;

    }
    
    /* Hide scrollbar while keeping scroll functionality */
    .full-page-success::-webkit-scrollbar {
        display: none; /* Hides scrollbar in WebKit browsers */
    }
    
    
    /* Prevent horizontal scrolling */
    body {
        overflow-x: hidden; /* Hides horizontal scrollbar */
    }
    
    /* For extra safety, apply to html too */
    html {
        overflow-x: hidden;
    }
    
    /* Header styles */
    .header-success {
        width: 100%;
        padding: 20px 0;
        
    }
    
    .header-success .logo-success {
        height: 60px;
        margin-bottom: 2%;
    }
    
    p {
        font-family: 'Montserrat', sans-serif;
    }
    
    /* Body content */
    .body-success {
        margin-top: -6%;
        flex: 1;
        flex-direction: column;
        max-width: 800px;
        padding: 20px;
    }
    
    .body_p-success {
        box-shadow: inset 3px 4px 15px rgba(0, 0, 0, 0.5);
        padding: 7%;
    }
    
    .body-success h1 {
        margin-top: 8%;
        margin-bottom: 40px;
        font-family: 'Bebas Neue', sans-serif;
        font-size: 70px;
        color: aliceblue;
        text-shadow:
        1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
        -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
        0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
    }
    
    .box-text { 
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
        padding: 4%;
    }
    .body-success p {
        font-size: 22px;
        line-height: 1.5;
        color: aliceblue;
        margin-bottom: 15px;
        font-family: 'Montserrat', sans-serif;
        text-shadow:
        1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
        -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
        0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
    }
    
    /* Form styles */
    /* Form container */
    .form-success {
        padding: 50px;
        background: linear-gradient(135deg, 
        #1d003a 0%,    /* very dark purple at bottom-right */
        #001f59 20%,   /* deep blue near bottom */
        #7a2d8c 40%,   /* bright purple smidge in the center */
        #3e0067 60%,   /* dark purple with a hint of brightness */
        #2e003e 80%,   /* dark purple near top */
        #1d003a 100%   /* very dark purple at top-left */
    );
        margin: 20px 0;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        border: black 1px dotted;
        width: 90%;
        max-width: 500px;
        height: 40%;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
        
    }
    
    .form-success p {
        text-shadow:
        1px 1px 2px rgba(0, 0, 0, 0.9), /* Dark outer shadow */
        -1px -1px 2px rgba(0, 0, 0, 0.7), /* Slightly lighter inner shadow */
        0px -2px 5px rgba(0, 0, 0, 0.8); /* More depth for the inner shadow */
        color: aliceblue;
        font-size: 1.2rem;
        margin-bottom: 10px;
    }
    
    .form-success .email-form-success {
        padding: 2%;
        font-size: 14px;
        margin-left: -5%;
        margin-top: 5%;
        width: 106%;
    }
    
    .form-success .query-form-success {
        padding: 10%;
        font-size: 14px;
        margin-left: -5%;
    }
    
    /* Email input box */
    .email-form-success {
        width: 30%; /* Smaller width */
        padding: 2px;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 10px;
        display: block; /* Stack on top of the query box */
        margin-left: auto;
        margin-right: auto;
    }
    
    /* Query input box */
    .query-form-success {
        align-items: center;
        width: 90%; /* Larger width */
        padding: 5px;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 10px;
        display: block; /* Stacked input fields */
        margin-left: auto;
        margin-right: auto;
    }
    
    /* Submit button */
    .form-button-success {
        width: 100px;
        padding: 3px;
        font-size: 10px;
        background-color: #007BFF;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        display: block;
        margin: 5px auto; /* Center the button */
    }
    
    .form-button-success:hover {
        background-color: #0056b3;
    }
    
    /* Footer styles */
    .footer-success {
        margin-top: 2%;
        width: 100%;
        color: #fff;
        padding: 10px 0;
        margin-bottom: 0%;
    }
    
    .footer-success .logo-success {
        margin-bottom: -1%;
        height: 55px;
        width: 105px;
    
    }
    
    
    .footer-success p {
        font-size: 0.8rem;
        margin-top: 1.5%;
        margin-bottom: -0.25%;
    }
};



/* not found page */
/* Structure for the full page when the page is not found */
/* Full-page container */

  

  
  /* Responsive Design: Making the layout more flexible on smaller devices */
  @media screen and (max-width: 768px) {
    .notfound {
      font-size: 2rem; /* Smaller font size for smaller screens */
    }
  
    .footer-notfound p {
      font-size: 0.9rem; /* Adjust footer text size for smaller screens */
    }
  
    .logo-notfound {
      max-width: 120px; /* Adjust logo size on mobile */
    }
  }
  
  /* @media screen and (max-width: 480px) {
    .logo-notfound {
      max-width: 100px; /* Ensure logo is small enough on very small screens */
    /* } */
 
    /* .notfound { */
      /* font-size: 1.5rem; Further decrease font size for small devices */
    /* } */
  /* } */ 

  /* Style the modal container */
.alert-container {
    display: none; /* Hidden by default */
    position: fixed;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Transparent black background */
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Style the alert box */
  .alert-box {
    background-color: #f9f9f9;
    color: black;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 300px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Button Style */
  .close-btn {
    background-color: darkblue;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .close-btn:hover {
    background-color: purple;
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }